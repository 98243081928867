import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import './styles.sass'

function DialogscapesTemplate(props) {

  const { data } = props
  const { edges: posts } = data.allMarkdownRemark

  const [activeDialog, setActiveDialog] = useState(null)

  function handleClick(index) {
    setActiveDialog(index)
  }

  function createUniformTitle(title) {
    if (title.includes('HR')) {
      return title.substring(0, title.indexOf(" "))
    } else {
      return title
    }
  }

  const croatianPosts = (
    posts
    .filter(({ node: post }) => post.frontmatter.title.includes('HR'))
  )
  
  const englishPosts = (
    posts
    .filter(({ node: post }) => !post.frontmatter.title.includes('HR'))
  )

  const [allPosts, setAllPosts] = useState(croatianPosts)
  
  function switchToEnglish() {
    setAllPosts(englishPosts)
  }

  function switchToCroatian() {
    setAllPosts(croatianPosts)
  }

  const englishButton = (
    <button onClick={switchToEnglish}>EN</button>
  )

  const croatianButton = (
    <button onClick={switchToCroatian}>HR</button>
  )

return (
        <>
          <div className='container__btn'>
            { allPosts[0].node.frontmatter.title.includes('HR') ? englishButton : croatianButton }
          </div>
          <div className='dialogscapes--container'>
            { allPosts && allPosts
                .map(({ node: post }, index) => {
                  const isActive = index === activeDialog
                  const dialogStyle = {
                    transform: isActive ? 'translate(50%, 0%)' : `translate(${getRandomNumber(-200, 200)}%, ${getRandomNumber(-50, 50)}%)`,
                    zIndex: index === activeDialog ? 9999 : index,
                  }
                
                return (
                  <div
                    className={`dialog ${activeDialog === index ? 'dialog--active' : ''}`}
                    style={dialogStyle}
                    key={post.id}
                    onClick={() => handleClick(index)}
                  >
                    <div className={`dialog--close ${post.frontmatter.title.includes('HR') ? `dialog__croatian` : `dialog__english`}`} onClick={(e) => {
                      e.stopPropagation() // Prevents the dialog from opening again when the close button is clicked
                      setActiveDialog(null)
                    }}>
                      x
                    </div>
                    <article
                      className={`${post.frontmatter.title.includes('HR') ? `dialog__croatian` : `dialog__english`}`}
                    >
                      <h4 className='dialog--title'>
                        [{createUniformTitle(post.frontmatter.title)}]
                      </h4>
                    {/* TODO: Find the non-dangerous way to render HTML*/}
                      <div
                        dangerouslySetInnerHTML={{ __html: post.html }}
                      />
                    </article>
                  </div>
                )
              })}
          </div>
        </>
    )
}

function getRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

Dialogscapes.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default function Dialogscapes() {
  return (
    <StaticQuery
      query={graphql`
        query DialogscapesQuery {
          allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { templateKey: { eq: "dialogscapes" } } }
          ) {
            edges {
              node {
                html
                excerpt(pruneLength: 400)
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  templateKey
                  description
                  tags
                }
              }
            }
          }
        }
      `}
      render={(data, count) => <DialogscapesTemplate data={data} count={count} />}
    />
  )
}
