import React, { useState } from "react"
import DialogscapesLayout from "../../components/DialogscapesLayout"
import Dialogscapes from "../../components/Dialogscapes"

const DialogscapesPage = () => {
  const [searchQuery, setSearchQuery] = useState('')

  const filterDialogscapes = post => {
    const isSearchMatch =
      searchQuery === '' ||
      post.frontmatter.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      post.frontmatter.description.toLowerCase().includes(searchQuery.toLowerCase());

    return isSearchMatch;
  };

  return (
    <DialogscapesLayout>
      <section className="section_dialogscapes">
        <div className="container">
          {/* <div className="search--box__dialogscapes">
            <input
              type="text"
              placeholder="Search dialogscapes"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <span className="search--icon"></span>
          </div> */}
          <div className="">
            <Dialogscapes />
          </div>
        </div>
      </section>
    </DialogscapesLayout>
  );
};

export default DialogscapesPage